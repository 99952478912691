import { Mark, mergeAttributes } from '@tiptap/core'

const Mistake = Mark.create({
  name: 'mistake',
  addAttributes() {
    return {
      error: {
        default: null,
        renderHTML: ({ error }) => {
          return {
            'data-tip-tap-id-param': error?.id,
            'data-tip-tap-index-param': error?.index,
            'data-tip-tap-diagnosis-param': error?.diagnosis,
            'data-tip-tap-corrections-param': error?.corrections,
            'data-tip-tap-length-param': error?.length,
            'data-tip-tap-type-param': error?.type,
            'data-tip-tap-correction-session-id-param': error?.correctionSessionId,
            'data-action':
              'click->tip-tap#displayExplanation DOMCharacterDataModified->tip-tap#change',
            class: `mistake_${error?.type}`
          }
        }
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'mark'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setMistake:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes)
        },
      unsetMistake:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        }
    }
  }
})

export default Mistake
