import Controller from '../application_controller'

export default class extends Controller {
  connect() {
    if (!this.iosApp) {
      return
    }
    this.timmer = setInterval(() => {
      if (this.element.selectionStart !== this.element.selectionEnd) {
        this.element.selectionStart = this.element.selectionEnd
      }
    }, 200)
  }

  disconnect() {
    this.timer && clearInterval(this.timer)
  }
}
