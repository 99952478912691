import Controller from '../application_controller'

// nos                0 1                      2
const vimeoPattern = /^(https:\/\/vimeo\.com)\/(.*)$/

export default class extends Controller {
  static targets = ['modal', 'player']

  connect() {
    import(/* webpackChunkName: "vimeo" */ '@vimeo/player')
      .then(Player => {
        this.Player = Player.default
      })
      .catch(error => {
        this.handleError(error)
      })
    $(this.modalTarget).on('hidden.bs.modal', this.closePlayer)
  }

  openPlayer(e) {
    try {
      const link = e.target.href || e.target.parentNode.href
      const matches = (link && link.match(vimeoPattern)) || []
      const isVimeoLink = !!matches[1]

      if (isVimeoLink) {
        e.preventDefault()
        this.player = new this.Player(this.playerTarget, {
          url: link,
          responsive: true
        })

        $(this.modalTarget).modal('show')
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  closePlayer = e => {
    this.player.destroy()
    this.playerTarget.innerHTML = ''
  }
}
