import Swal from 'sweetalert2'
import { wordsCountCampaignDone, newBadge, reviewButtons } from './notifications'

export async function swalNewBadge(title, message, image) {
  return Swal.fire({
    title: title,
    icon: 'success',
    html: newBadge(message, image),
    showCancelButton: false,
    confirmButtonText: 'Super',
    width: '60em',
    customClass: {
      confirmButton: 'btn btn-primary'
    }
  })
}

export async function swalWordsCountCampaignDone(title, message, media_link) {
  return Swal.fire({
    title: title,
    icon: 'success',
    html: wordsCountCampaignDone(message, media_link),
    showCancelButton: false,
    confirmButtonText: 'En avant l’écriture',
    width: '60em',
    customClass: {
      confirmButton: 'btn btn-primary'
    }
  })
}

export async function swalInformation(notification) {
  const {
    title,
    message,
    swal: {
      type,
      confirmButtonText,
      cancelButtonText,
      showCancelButton,
      showConfirmButton,
      confirmButtonClass,
      cancelButtonClass,
      width,
      ...rest
    }
  } = notification
  return Swal.fire({
    icon: type || 'info',
    title: title,
    text: message,
    showCancelButton: showCancelButton || false,
    cancelButtonText: cancelButtonText || 'Continuer',
    showConfirmButton: showConfirmButton || true,
    confirmButtonText: confirmButtonText || "C'est noté!",
    width: width || '60em',
    customClass: {
      confirmButton: confirmButtonClass || 'btn btn-primary',
      cancelButton: cancelButtonClass || 'btn'
    },
    ...rest
  })
}

export async function swalNewReview(kidStoryId) {
  const reviewSteps = Swal.mixin({
    confirmButtonText: 'Suivant &rarr;',
    progressSteps: ['1', '2'],
    width: '60em'
  })

  return reviewSteps
    .fire({
      title: 'As-tu aimé cette histoire ?',
      html: reviewButtons(kidStoryId),
      showConfirmButton: false,
      customClass: {
        confirmButtonClass: 'hidden'
      },
      showCancelButton: false
    })
    .then(() => {
      return reviewSteps.fire({
        title: "Qu'as-tu aimé/préféré dans cette histoire ?",
        input: 'textarea',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn'
        }
      })
    })
}

export async function swalVisitorType(e, mobile) {
  return Swal.fire({
    showCancelButton: true,
    title: 'Qui est là ?',
    confirmButtonText: 'Je suis un adulte',
    cancelButtonText: 'Je suis un enfant',
    customClass: {
      confirmButton: 'btn btn-primary m-2',
      cancelButton: 'btn btn-warning m-2'
    },
    buttonsStyling: false
  }).then(result => {
    if (result.value) {
      let action = mobile ? 'adultFromMobileApp' : 'adult'
      if (typeof gtag === 'function') {
        gtag('event', action, {
          event_category: 'visitorType'
        })
      }
      window.location.replace('/users/sign_up')
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      let action = mobile ? 'kidFromMobileApp' : 'kid'
      gtag('event', action, {
        event_category: 'visitorType'
      })
      Swal.fire({
        title: "Demande de l'aide à un adulte !",
        text: "Pour pouvoir accéder à l'application il faut créer un compte, demande de l'aide à un adulte.",
        showCancelButton: false,
        confirmButtonText: 'Je suis un adulte'
      }).then(result => {
        if (typeof gtag === 'function') {
          let action = mobile ? 'adultAfterKidFromMobileApp' : 'adultAfterKid'
          gtag('event', action, {
            event_category: 'visitorType'
          })
        }
        window.location.replace('/users/sign_up')
      })
    }
  })
}

export async function teacherSubscriptionUpSale(e, mobile, title, text, confirmButton, footer) {
  return Swal.fire({
    icon: 'warning',
    title: title || 'Cette fonctionnalité est verrouillée  !',
    confirmButtonText: confirmButton || 'Découvrir cette offre',
    text:
      text ||
      "Passez à l'offre Premium et débloquez le plein potentiel de Plume pour les enseignants.",
    footer:
      `<p class="test-center">${footer}</p>` ||
      '<p class="test-center">Nombre de classes illimité, accès à toute la bibliothèque, à tous les outils de correction et plus encore !</p>'
  }).then(result => {
    if (result.value) {
      window.location.assign('/professionnels')
    }
  })
}

export async function swalNewCorrectionRequest(notification) {
  return Swal.fire({
    title: notification.title,
    html: notification.message,
    icon: 'success',
    confirmButtonText: notification.swal?.confirmationButtonText || 'Envoyer en relecture',
    width: '60em',
    customClass: {
      confirmButton: 'btn btn-primary'
    }
  })
}

export async function swalReviewConfirm() {
  return Swal.fire({
    title: 'Merci pour ton avis !',
    confirmButtonText: 'Fini'
  })
}

export async function swalCorrectionRequestConfirm(message, buttonText = 'Fini') {
  return Swal.fire({
    text: message,
    confirmButtonText: buttonText
  })
}
