import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'
import { isTestEnvironement } from '../../../frontend/utils'

export default class extends Controller {
  static values = { sound: String, playOnConnect: Boolean }

  async connect() {
    if (!this.playOnConnectValue) return

    this.dispatch('confetti:start', { prefix: false })

    if (this.hasSoundValue) {
      this.dispatch('play:sound', { detail: { sound: this.soundValue }, prefix: false })
    }

    await this.play()
    this.dispatch('confetti:end', { prefix: false })
  }

  disconnect() {
    try {
      confetti.reset()
    } catch (error) {}
    this.element.remove()
  }

  // actions

  async play() {
    if (isTestEnvironement) return

    function r(min, max) {
      return Math.random() * (max - min) + min
    }

    return confetti({
      useWorker: false,
      angle: r(55, 125),
      spread: r(50, 70),
      particleCount: r(75, 150),
      ticks: 250,
      shapes: ['circle', 'circle', 'square'],
      colors: [
        '#e15353',
        '#fa5c5c',
        '#fed6d6',
        '#fff7f7',
        '#df7319',
        '#fd7e14',
        '#fdd6bf',
        '#fff5ef',
        '#28b97b',
        '#38cd8b',
        '#abebd0',
        '#eafaf3',
        '#f5d944',
        '#fbf0b4',
        '#fefbec',
        '#3d79ee',
        '#cfdefb',
        '#f5f8fe',
        '#71b5bd',
        '#c8f2f7',
        '#f2fafb',
        '#f7b2d3',
        '#fce0ed',
        '#fef7fb'
      ],
      origin: {
        y: 0.6
      },
      zIndex: 9999999
    })
  }
}
