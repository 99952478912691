import Controller from './game_controller'
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input', 'completedButton']
  static values = { answer: String, autofocus: Boolean }
  selectedFieldIndex = 0

  initialize() {
    useDispatch(this, { eventPrefix: false })
  }

  // actions
  typePunctuation(e) {
    this.inputTargets[this.selectedFieldIndex].value = e.currentTarget.dataset.key
    this.selectedFieldIndex++

    this.hasPlayed = true
    this.dispatch('game:played')
    this.dispatch('play:sound', { randomSounds: ['kick1', 'kick2'] })
    this.#completedInputs()
  }

  navigate({ shiftKey, code }) {
    switch (code) {
      case 'ArrowLeft':
        this.selectedFieldIndex--
        break
      case 'ArrowRight':
        this.selectedFieldIndex++
        break
      case 'Tab':
        shiftKey ? this.selectedFieldIndex-- : this.selectedFieldIndex++
        break
    }
  }

  select(e) {
    const index = this.inputTargets.indexOf(e.currentTarget)
    this.selectedFieldIndex = index
  }

  // private

  validate(e) {
    e.preventDefault()
    e.stopPropagation()

    this.highlightErrors()
    this.clearErrors(1000)

    if (this.isAnswerCorrect) {
      this.success()
    } else {
      this.error()
      this.selectFirstError()
    }
  }

  selectFirstError() {
    const index = this.inputTargets.indexOf(this.firstWrongInput)
    this.selectedFieldIndex = index
  }

  highlightErrors() {
    this.inputTargets.forEach(input => {
      if (input.value == input.dataset.answer) {
        input.dataset.correctAnswer = true
      } else {
        input.dataset.correctAnswer = false
      }
    })
  }
  clearErrors(wait = 1000) {
    setTimeout(() => {
      this.inputTargets.forEach(input => {
        input.dataset.correctAnswer = null
      })
    }, wait)
  }

  /////////////////////////////////////////////
  // Private Methods and Getters             //
  /////////////////////////////////////////////

  #toggleButtonDisplay(visible) {
    if (visible) {
      this.dispatch(`${this.gameIdValue}:showButton`)
    }
  }

  #completedInputs() {
    let allCompleted = true
    this.inputTargets.forEach(input => {
      if (input.value.length == 0) {
        allCompleted = false
      }
    })
    this.#toggleButtonDisplay(allCompleted)
  }

  get isAnswerCorrect() {
    return this.inputTargets.filter(input => input.value !== input.dataset.answer).length === 0
  }

  get firstWrongInput() {
    return this.inputTargets.find(input => {
      return input.value !== input.dataset.answer
    })
  }

  set selectedFieldIndex(index) {
    const rest = index % this.inputTargets.length
    const newIndex = index >= 0 ? Math.min(Math.max(0, rest), this.lastIndex) : this.lastIndex

    this._selectedFieldIndex = newIndex

    requestAnimationFrame(() => {
      const input = this.inputTargets[newIndex]
      input?.focus()
      this.inputTargets.forEach((input, inputIndex) =>
        input.classList.toggle('animate-heart-beat', newIndex === inputIndex)
      )
    })
  }

  get selectedFieldIndex() {
    return this._selectedFieldIndex
  }

  get lastIndex() {
    return this.inputTargets.length - 1
  }
}
