import Controller from '../application_controller'

export default class extends Controller {
  static values = { closest: String }

  // <div data-controller="html" data-action="click->html#remove">
  remove() {
    this.element.remove()
  }

  // <div data-controller="html" data-action="click->html#removeClosest" data-closest-value="li">
  removeClosest(e) {
    e.preventDefault()
    try {
      const closestElementName = this.hasClosestValue
        ? this.closestValue
        : e.currentTarget.dataset.closest
      if (closestElementName) {
        const element = this.element.closest(closestElementName)
        element.remove()
      } else {
        this.element.remove()
      }
    } catch (error) {
      this.handleError(error)
    }
  }
}
