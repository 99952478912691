export default class SchoolDataAdapter {
  constructor(schoolData, locale) {
    this.schoolData = schoolData
    this.locale = locale
  }

  formData() {
    if (this.locale === 'fr') {
      return this.#frFormData()
    } else if (this.locale === 'en') {
      return this.#usFormData()
    }
  }

  #usFormData() {
    return {
      nameTarget: this.schoolData.NAME,
      zipInputTarget: this.schoolData.ZIP,
      cityInputTarget: this.schoolData.CITY,
      streetInputTarget: this.schoolData.ADDRESS,
      countryInputTarget: 'US',
      academyInputTarget: this.schoolData.COUNTY,
      districtInputTarget: this.schoolData.COUNTY,
      stateInputTarget: this.schoolData.STATE,
      phoneInputTarget: this.schoolData.TELEPHONE,
      gradeInputTarget: `${this.schoolData.ST_GRADE} - ${this.schoolData.END_GRADE}`,
      populationInputTarget: this.schoolData.POPULATION,
      countryUniqIdInputTarget: this.schoolData.NCESID,
      contractTypeTarget: this.#getTypeFromUrl(this.schoolData.SOURCE)
    }
  }

  #frFormData() {
    return {
      nameTarget: this.schoolData.appellation_officielle,
      zipInputTarget: this.schoolData.code_postal_uai,
      cityInputTarget: this.schoolData.libelle_commune,
      streetInputTarget: this.schoolData.adresse_uai,
      countryInputTarget: 'FR',
      academyInputTarget: this.schoolData.libelle_academie,
      districtInputTarget: this.schoolData.libelle_academie,
      countryUniqIdInputTarget: this.schoolData.numero_uai,
      stateInputTarget: '',
      phoneInputTarget: '',
      gradeInputTarget: '',
      populationInputTarget: '',
      contractTypeTarget: this.schoolData.secteur_public_prive_libe
    }
  }

  #getTypeFromUrl(url) {
    const urlSearchParams = new URLSearchParams(url.split('?')[1])
    return urlSearchParams.get('Type')
  }
}
