import Controller from '../application_controller'
import { immersiveReaderToken } from 'api'
import { getMetaValue } from '../../utils/index.js'

export default class extends Controller {
  static values = {
    locale: { type: String, default: getMetaValue('locale') },
    paragraphs: String,
    title: String,
    subdomain: String
  }

  connect() {
    import('@microsoft/immersive-reader-sdk')
      .then(({ launchAsync }) => {
        this.launchAsync = launchAsync
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  async openReader() {
    if (this.open) return

    try {
      this.open = true
      const token = await immersiveReaderToken()
      this.launchAsync(token, this.subdomainValue, this.content, {
        uiLang: this.language,
        uiZIndex: 100000000,
        onExit: () => {
          this.open = false
        }
      })
      this.sendGaEvent()
    } catch (error) {
      this.open = false
      this.handleError(error)
    }
  }

  // private

  sendGaEvent() {
    if (typeof gtag === 'function') {
      gtag('event', 'initiate', {
        event_category: 'immersiveReader',
        event_label: this.storyTitle + ' - Chapitre ' + this.chapterId
      })
    }
  }

  get content() {
    return {
      title: this.titleValue,
      chunks: [
        {
          content: this.paragraphsValue,
          lang: this.language,
          mimeType: 'text/plain'
        }
      ]
    }
  }

  get language() {
    return this.localeValue
  }
}
