import Shepherd from 'shepherd.js'
import { TemplateInstance } from '@github/template-parts'

export default class Tour {
  /////////////////////////////////////////////
  // Data                                    //
  /////////////////////////////////////////////

  static defaultStepOptions = {
    scrollTo: true,
    classes: 'plume-product-tour'
  }

  /////////////////////////////////////////////
  // Constructor && Public Accessors         //
  /////////////////////////////////////////////

  #slug
  #steps
  #skippedSteps
  #tourFor
  #textToSpeech
  #cta
  #handleCancel
  #handleComplete
  #tour

  constructor({ slug, steps, tourFor, textToSpeech, cta }, handleCancel, handleComplete) {
    this.#slug = slug
    this.#steps = steps
    this.#skippedSteps = []
    this.#tourFor = tourFor || 'kid'
    this.#cta = cta
    this.#textToSpeech = textToSpeech
    this.#handleCancel = handleCancel
    this.#handleComplete = handleComplete
    this.#tour = this.#initializeTour()

    this.#addStepsToTour()
    this.#initializeEvents()
  }

  /////////////////////////////////////////////
  // Public Methods                          //
  /////////////////////////////////////////////

  start() {
    setTimeout(() => {
      this.#tour.start()
    }, 800)
  }

  hide() {
    this.#tour.hide()
  }

  /////////////////////////////////////////////
  // Private Methods and Getters             //
  /////////////////////////////////////////////

  #initializeTour() {
    return new Shepherd.Tour({
      defaultStepOptions: Tour.defaultStepOptions,
      tourName: this.#slug,
      useModalOverlay: true,
      exitOnEsc: true
    })
  }

  #initializeEvents() {
    const startEvent = new Event('pause:notifications')
    const stopEvent = new Event('resume:notifications')

    Shepherd.on('start', () => {
      document.dispatchEvent(startEvent)
    })
    Shepherd.on('cancel', () => {
      this.#handleCancel(this.#slug)
      document.dispatchEvent(stopEvent)
    })
    Shepherd.on('complete', () => {
      this.#handleComplete(this.#slug)
      document.dispatchEvent(stopEvent)
    })
  }

  #addStepsToTour() {
    this.#steps.forEach((step, index) => {
      this.#addStepToTour(step, index)
    })
  }

  #addStepToTour(step, index) {
    if (!Tour.#shouldBeDisplayed(step)) return this.#skippedSteps.push(step)
    const updatedIndex = this.#skippedSteps.length === 0 ? index : index - this.#skippedSteps.length
    this.#tour.addStep({
      ...step,
      when: {
        show: () => {
          this.#positionElementAndDisplayStepPosition(step, updatedIndex)
          if (this.#textToSpeech === true || this.#textToSpeech === undefined)
            return this.#addTextToSpeech(step)
        }
      },
      buttons: this.#localizedButtons(updatedIndex, step.cta),
      showOn: () => Tour.#isElementPresentToAttachStep(step),
      popperOptions: { modifiers: [{ name: 'offset', options: { offset: [0, 30] } }] },
      cancelIcon: { enabled: index === 0 },
      scrollTo: { behavior: 'smooth', block: 'center' }
    })
  }

  #positionElementAndDisplayStepPosition(step, index) {
    const currentStepElement = this.#tour.currentStep.el
    if (step.sticky) currentStepElement.classList.add('product-tour-stick-to-bottom')
    if (index === 0) return

    const title = currentStepElement.querySelector('.shepherd-title')
    title.insertAdjacentHTML(
      'beforeEnd',
      `<span class="float-right small">${index} / ${this.#stepsCount - 1}</span>`
    )
  }

  #addTextToSpeech(step) {
    const currentStepElement = this.#tour.currentStep.el
    const title = currentStepElement.querySelector('.shepherd-title')
    const text = `${step.title}. ${step.text}`.replace(/<[^>]*>/g, '')
    const template = new TemplateInstance(document.querySelector('#tts_component'), {
      text,
      contentClass: 'hidden',
      iconSize: 'lg'
    })

    title.prepend(template)
  }

  #localizedButtons(index, customCta) {
    if (!this.#isMultiStepsTour) return [this.#doneButton(customCta)]
    if (index === 0) return [this.#laterButton(customCta), this.#startButton(customCta)]
    if (index === this.#stepsCount - 1) return [this.#doneButton(customCta)]

    return [this.#backButton(customCta), this.#nextButton(customCta)]
  }

  #doneButton(customCta) {
    return {
      text: customCta?.done || this.#cta.done,
      action: this.#tour.complete
    }
  }

  #laterButton(customCta) {
    return {
      text: customCta?.later || this.#cta.later,
      action: this.#tour.cancel,
      secondary: true
    }
  }

  #startButton(customCta) {
    return {
      text: customCta?.start || this.#cta.start,
      action: this.#tour.next
    }
  }

  #backButton(customCta) {
    return {
      text: customCta?.back || this.#cta.back,
      action: this.#tour.back,
      secondary: true
    }
  }

  #nextButton(customCta) {
    return {
      text: customCta?.next || this.#cta.next,
      action: this.#tour.next
    }
  }

  get #stepsCount() {
    return this.#steps.length - this.#skippedSteps.length
  }

  get #isMultiStepsTour() {
    return this.#stepsCount > 1
  }

  /////////////////////////////////////////////
  // Static Methods                          //
  /////////////////////////////////////////////

  static #isElementPresentToAttachStep(step) {
    return !step.attachTo || !!document.querySelector(step.attachTo.element)
  }

  static #shouldBeDisplayed(step) {
    return (!!step.attachTo && !!document.querySelector(step.attachTo.element)) || !step.attachTo
  }
}
