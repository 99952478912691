import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    plugin: String,
    activityId: Number,
    writableId: Number,
    writingId: Number,
    resourceGid: String,
    kidId: Number
  }

  computeSuggestion(e) {
    e.preventDefault()

    post('/bird/chatters', {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      },
      query: {
        writable_id: this.writableIdValue,
        activity_id: this.activityIdValue,
        writing_id: this.writingIdValue,
        kid_id: this.kidIdValue,
        resource_gid: this.resourceGidValue,
        writing_with_prompt: this.#prose(),
        kid_writing: this.#prose(),
        plugin: this.pluginValue
      }
    })
  }

  #prose() {
    return document.querySelector('.ProseMirror').textContent
  }
}
