import Controller from '../application_controller'
import { useFieldAutoResize } from '../../mixin/useFieldAutoResize'
import { useDebounce, useDispatch } from 'stimulus-use'
import { timingByEnv, isTestEnvironement } from '../../utils'

export default class extends Controller {
  static targets = ['submit', 'textarea', 'check', 'spinner', 'errorMessage', 'errorTemplate']
  static debounces = [
    { name: 'saveStory', wait: timingByEnv(1000) },
    { name: 'markAsSaved', wait: timingByEnv(500) },
    { name: 'submitRegularly', wait: timingByEnv(1500) }
  ]
  static values = { contentLength: Number, wordCountTarget: Object, disabled: Boolean }

  initialize() {
    useDebounce(this)
    useDispatch(this, { eventPrefix: false })
  }

  connect() {
    if (!this.hasTextareaTarget) return

    this.element[this.identifier] = this
    this.loadStory()

    this.previousScrollHeight = this.hasTextareaTarget ? this.textareaTarget.scrollHeight : 0
    if (this.contentLengthValue < 2000) {
      // when the text is very long and the size of the text area longer that the viewport size
      // there is a very inconvinient scroll happening
      useFieldAutoResize(this, {
        fieldElement: this.textareaTarget,
        scrollRootElement: document.querySelector('[data-scroll-container]')
      })
    }

    requestAnimationFrame(() => {
      this.enableNext()
    })
  }

  // actions

  validateWordCount(e) {
    const wordCount = (this.textareaTarget.value.match(/(\p{Letter}|\d)+/gu) || []).length
    if (
      (this.wordCountTargetValue.min > 1 && wordCount < this.wordCountTargetValue.min) ||
      (this.wordCountTargetValue.max >= 1 && wordCount > this.wordCountTargetValue.max)
    ) {
      e.preventDefault()
      e.stopImmediatePropagation()
      this.displayErrorMessage()
    }
  }

  insert(e) {
    e.preventDefault()
    const cursorPosition = this.textareaTarget.selectionEnd
    const character = e.target.dataset.content
    requestAnimationFrame(() => {
      const string = insert(this.textareaTarget.value, cursorPosition, character)
      this.textareaTarget.value = string
      this.textareaTarget.selectionEnd = cursorPosition + 1
      this.textareaTarget.focus()
      this.submitRegularly()
    })
  }

  submitRegularly(e) {
    this.submitTargets.forEach(button => button.classList.add('saving'))
    this.submitTargets.forEach(button => button.classList.remove('todo', 'done'))
    this.element.requestSubmit()
  }

  markAsSaved(e) {
    this.clearStory()
    const [_data, _status, xhr] = e.detail
    this.submitTargets.forEach(button => (button.outerHTML = xhr.response))
  }

  enableNext() {
    if (!this.hasSubmitTarget || !this.hasTextareaTarget) return
    if (this.disabledValue) return

    if (this.textareaTarget.value.length >= 2 && this.kidId) {
      this.submitTargets.forEach(button => (button.disabled = false))
    } else {
      this.submitTargets.forEach(button => (button.disabled = true))
    }
  }

  saveStory() {
    try {
      const story = {
        updatedAt: Date.now(),
        content: this.textareaTarget.value
      }
      localStorage.setItem(
        ['histoires_plume'],
        JSON.stringify({ ...this.cachedStories, [this.key]: story })
      )
    } catch (err) {
      // an error occurs if the user disabled the localStorage so we ignore it.
      console.log(err)
    }
  }

  loadStory() {
    try {
      const { content, updatedAt } = this.cachedStories[this.key] || {}
      if (content && updatedAt > this.writingUpdatedAt) {
        this.textareaTarget.value = content
        const wordCount = (content.match(/(\p{Letter}|\d)+/gu) || []).length

        this.dispatch('chapter:started')
        this.dispatch('writing--component:updated', {
          detail: { wordCount: wordCount },
          target: this.wordCountTarget
        })
      }
    } catch (err) {
      this.handleError(err)
    }
  }

  clearStory() {
    try {
      const storage = this.cachedStories
      delete storage[this.key]
      localStorage.setItem(['histoires_plume'], JSON.stringify(storage))
    } catch (err) {
      // an error occurs if the user disabled the localStorage so we ignore it.
      console.log(err)
    }
  }

  displayErrorMessage() {
    this.errorMessageTarget.innerHTML = ''
    this.errorMessageTarget.append(this.errorTemplateTarget.content.cloneNode(true))

    this.errorMessageTarget.classList.add('opacity-100')
    this.errorMessageTarget.classList.remove('opacity-0')
    this.dispatch('play:sound', { sound: 'gong' })

    setTimeout(() => {
      this.errorMessageTarget.classList.remove('opacity-100')
      this.errorMessageTarget.classList.add('opacity-0')
    }, 4000)
  }

  submit() {
    requestAnimationFrame(() => this.element.requestSubmit())
  }

  get cachedStories() {
    try {
      return JSON.parse(localStorage.getItem('histoires_plume')) || {}
    } catch (err) {
      return {}
    }
  }

  get key() {
    if (this.isCorrectionMode) {
      return `correction_${this.writingId}`
    } else {
      const { kidStoryId, kidId, storyId, chapterId } = this
      return `kid-story_${kidStoryId}-story_${storyId}-chapter_${chapterId}-kid_${kidId}`
    }
  }

  get isCorrectionMode() {
    if (this.data.has('correctionMode')) {
      return this.data.get('correctionMode') === 'true'
    }
    return false
  }

  get writingUpdatedAt() {
    return this.data.get('updatedAt')
  }

  get writingId() {
    return this.data.get('writingId')
  }
}

const insert = (str, index, value) => {
  return str.substr(0, index) + value + str.substr(index)
}
