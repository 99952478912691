import Controller from '../application_controller'

export default class extends Controller {
  static targets = ['original', 'correction', 'result']
  static values = {
    greenClass: { type: String, default: 'highlight-green' },
    redClass: { type: String, default: 'highlight-red' },
    greyClass: { type: String, default: 'highlight-grey' }
  }

  connect() {
    import(/* webpackChunkName: "diff" */ 'diff')
      .then(Diff => {
        this.diffWords = Diff.diffWords
        this.compare()
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  compare() {
    const diff = this.diffWords(
      this.originalTarget.value || this.originalTarget.textContent,
      this.correctionTarget.value || this.correctionTarget.textContent
    )
    const fragments = []
    diff.forEach(part => {
      if (part.removed) return
      const color = part.added ? 'green' : part.removed ? 'red' : 'grey'
      fragments.push(`<span class="${this.colorClassFor(color)}">${part.value}</span>`)
    })
    this.resultTarget.innerHTML = fragments.join('')
  }

  colorClassFor(color) {
    switch (color) {
      case 'green':
        return this.greenClassValue
      case 'red':
        return this.redClassValue
      case 'grey':
        return this.greyClassValue
    }
  }
}
