import Controller from '../application_controller'
import { useDebounce, useThrottle, useDispatch } from 'stimulus-use'
import { post } from '@rails/request.js'
import clsx from 'clsx'

import { Editor, getMarkAttributes, getMarkRange, getMarkType } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

import Mistake from '../../models/tip_tap/mistake'
import Typography from '../../models/tip_tap/typography'

export default class extends Controller {
  static debounces = ['handleProofreadingUpdate', 'change']
  static throttles = ['displayExplanation']
  static targets = [
    'editor',
    'contentField',
    'error',
    'textToSpeechContent',
    'toggleBold',
    'toggleItalic',
    'toggleUnderline',
    'loader'
  ]
  static values = {
    content: String,
    analysisUrl: String,
    explanationUrl: String,
    proofreading: { type: Boolean, default: false },
    typography: { type: Boolean, default: false },
    richText: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    attributes: { type: Object, default: {} },
    boldState: { type: Boolean, default: false },
    italicState: { type: Boolean, default: false },
    underlineState: { type: Boolean, default: false },
    automatedCorrection: { type: String, default: '' },
    busy: { type: Boolean, default: false }
  }

  connect() {
    if (this.isPreview) return

    useDebounce(this, { wait: 3000 })
    useThrottle(this, { wait: 500 })
    useDispatch(this, { eventPrefix: false })

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: this.extensions,
      content: this.#writingContentToHTML,
      editorProps: {
        attributes: {
          class: clsx(
            'p-4 min-h-[10rem] border bg-white rounded body--lg outline-blue-light',
            this.hasTextToSpeechContentTarget && 'pl-12'
          ),
          ...this.attributesValue
        }
      }
    })

    if (this.proofreadingValue) {
      this.editor.on('update', this.handleProofreadingUpdate.bind(this))
      this.editor.on('selectionUpdate', this.#handleSelectionUpdate.bind(this))
      this.#performInitialAnalysis()
    } else {
      this.editor.on('update', this.#handleNormalUpdate.bind(this))
      // This is to update the rich text button status according to the text selection
      this.editor.on('selectionUpdate', this.checkToggleButtonStatus.bind(this))
    }

    import(/* webpackChunkName: "diff" */ 'diff')
      .then(Diff => {
        this.diffWords = Diff.diffWords
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  // actions

  change() {
    const currentAnchor = this.editor.state.selection
    const currentSchema = this.editor.state.schema
    let mistakeRange = getMarkRange(currentAnchor.$from, getMarkType('mistake', currentSchema))

    if (!mistakeRange) return

    try {
      this.editor
        .chain()
        .setTextSelection(mistakeRange)
        .command(({ commands }) => commands.unsetMistake())
        .setTextSelection(currentAnchor)
        .run()
    } catch (error) {
      this.handleError(error)
    }
  }

  toggleBold() {
    if (this.boldStateValue) {
      this.editor.commands.unsetBold()
    } else {
      this.editor.commands.setBold()
    }
    this.boldStateValue = !this.boldStateValue
    this.editor.commands.focus()
  }

  toggleItalic() {
    if (this.italicStateValue) {
      this.editor.commands.unsetItalic()
    } else {
      this.editor.commands.setItalic()
    }
    this.italicStateValue = !this.italicStateValue
    this.editor.commands.focus()
  }

  toggleUnderline() {
    if (this.underlineStateValue) {
      this.editor.commands.unsetUnderline()
    } else {
      this.editor.commands.setUnderline()
    }
    this.underlineStateValue = !this.underlineStateValue
    this.editor.commands.focus()
  }

  autoCorrect(e) {
    this.automatedCorrectionValue = ''
    this.busyValue = true

    post('/open_ai/corrections', {
      body: {
        text: this.plainText,
        target_id: this.element.id,
        resource_gid: this.element.dataset.gid
      },
      headers: {
        Accept: 'application/json'
      }
    })
  }

  automatedCorrectionValueChanged(correctedText) {
    if (!correctedText) return
    // here we receive the call back from the background job that update the attribute

    const diff = this.diffWords(this.plainText, correctedText)

    const fragments = []
    diff.forEach(part => {
      if (part.removed) return
      const color = part.added ? 'green' : part.removed ? 'red' : 'unset'
      fragments.push(`<span style="color: ${colorStyleFor(color)};">${part.value}</span>`)
    })
    this.editor.commands.setContent(fragments.join(''))
    this.contentFieldTarget.value = this.text
    this.dispatch('content:changed', { text: this.plainText })

    function colorStyleFor(color) {
      switch (color) {
        case 'green':
          return 'var(--color-green)'
        case 'red':
          return 'var(--color-red)'
        default:
          return 'unset'
      }
    }
  }

  checkToggleButtonStatus() {
    this.boldStateValue = this.editor.isActive('bold')
    this.italicStateValue = this.editor.isActive('italic')
    this.underlineStateValue = this.editor.isActive('underline')
  }

  displayExplanation(e) {
    const params = e?.params ? e.params : this.currentMistakeAttributes // event is present on hover event

    post(this.explanationUrlValue, {
      body: { ...params },
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
  }

  // callback

  boldStateValueChanged(value) {
    if (this.hasToggleBoldTarget) {
      this.toggleBoldTargets.forEach(toggleBold => {
        toggleBold.classList.toggle('active', value)
      })
    }
  }

  italicStateValueChanged(value) {
    if (this.hasToggleItalicTarget) {
      this.toggleItalicTargets.forEach(toggleItalic => {
        toggleItalic.classList.toggle('active', value)
      })
    }
  }

  underlineStateValueChanged(value) {
    if (this.hasToggleUnderlineTarget) {
      this.toggleUnderlineTargets.forEach(toggleUnderline => {
        toggleUnderline.classList.toggle('active', value)
      })
    }
  }

  errorTargetConnected(target) {
    // errors arrives from a Turbo Stream. As they are Target there is this automatic callback
    const correctionSessionId = target.dataset.correctionSessionId
    const errors = JSON.parse(target.dataset.errors)

    if (!errors) return

    errors.map(error => {
      error.correctionSessionId = correctionSessionId
    })

    const currentAnchor = this.editor.state.selection // get the current cursor position
    try {
      this.editor
        .chain()
        .selectAll()
        // .unsetAllMarks()
        .unsetMark('mistake')
        .command(({ commands }) => this.#setMistakes(commands, errors))
        .setTextSelection(currentAnchor)
        .focus()
        .run()
    } catch (error) {
      this.handleError(error)
    }
  }

  busyValueChanged(value) {
    if (!this.hasLoaderTarget) return

    if (value) {
      this.loaderTarget.classList.remove('invisible')
    } else {
      this.loaderTarget.classList.add('invisible')
    }
  }
  // private

  #setMistakes(commands, errors) {
    errors.forEach(error => this.#setMistake(commands, error))
  }

  #setMistake(commands, error) {
    commands.setTextSelection({
      from: error.index + 1,
      to: error.index + error.length + 1
    })
    commands.setMistake({ error })
  }

  #handleNormalUpdate() {
    this.contentFieldTarget.value = this.text
    if (this.hasTextToSpeechContentTarget)
      this.textToSpeechContentTarget.dataset.text = this.plainText
    this.dispatch('content:changed', { text: this.plainText })
  }

  handleProofreadingUpdate() {
    this.#performAnalysis()
  }

  #handleSelectionUpdate() {
    if (this.lastMistakeAttributes === this.currentMistakeAttributes) return

    this.lastMistakeAttributes = this.currentMistakeAttributes

    if (this.correctingMistake) {
      this.dispatch('waitForContent:bubble')
      this.displayExplanation()
    } else {
      if (this.errors.length > 0) {
        this.dispatch('close:bubble')
      }
    }
  }

  async #performAnalysis() {
    const unChanged = this.contentFieldTarget.value === this.text
    if (unChanged) return

    this.contentFieldTarget.value = this.text

    await post(this.analysisUrlValue, {
      body: { text: this.plainText },
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
  }

  #performInitialAnalysis() {
    post(this.analysisUrlValue, {
      body: { text: this.plainText, initial: true },
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
  }

  // getters

  get extensions() {
    const placeHolder = Placeholder.configure({
      placeholder: this.placeholderValue,
      showOnlyWhenEditable: false,
      showOnlyCurrent: false,
      includeChildren: true
    })

    let extensions = [placeHolder, Document, Text, History, Paragraph, Mistake, TextStyle, Color]

    if (this.richTextValue) {
      extensions = [Bold, Italic, Underline, ...extensions]
    }

    if (this.typographyValue) {
      extensions.push(Typography)
    }
    return extensions
  }

  get #writingContentToHTML() {
    const rawContent = this.contentValue || this.contentFieldTarget.value
    if (rawContent === '') return ''

    const content = rawContent.replace(/(\r\n|\r|\n)/g, '</p><p>')
    return `<p>${content}</p>`
  }

  get currentMistakeAttributes() {
    // return the current mistake information given the position of the cursor
    return getMarkAttributes(this.editor.state, 'mistake')?.error
  }

  get correctingMistake() {
    return !!this.currentMistakeAttributes
  }

  get text() {
    return this.editor
      .getHTML()
      .replace(/<\/p><p>/g, '\n') // replace empty HTML paragraphs with empty line
      .replace(/<\/?p>/g, '') // remove HTML paragraph tags
      .replace(/<\/?mark(->|[^>])*>/g, '') // remove HTML mistake mark tags (mark contains Stimulus actions with ->)
  }

  get plainText() {
    var dom = new DOMParser().parseFromString(this.editor.getHTML(), 'text/html')
    return dom.body.textContent
  }

  get errors() {
    try {
      return JSON.parse(this.errorTarget.dataset.errors)
    } catch (error) {
      return []
    }
  }
}

// c’est une forterese sinistre qui se dresse devant eux. Des créature vêtues de noir en garde l’entrée. Il semble impossible de s’y aventurer sens devoir d’abord les affronter. alix est terrifié et la licorn paraît tout aussi inquiète. Il est évident qu'elles n’y arriveront pas seules, qu'elles ont besoin de soutien. peut-être qu’une diversion lur permettrai de se faufilé discrètement à travers les remparts…
