export const newBadge = (message, image) => `
	<div>
		<div>${message}</div>
		<div>${image}</div>
	</div>
	`

export const reviewButtons = kidStoryId => `
	<div id="swal2-buttons-container" class="swal2-buttons-container">
		<div class="flex flex-col items-center pr-6" data-value="1" data-kid-story-id="${kidStoryId}" data-action="click->notification#setRating"><button class="swal2-logo swal2-button-1"></button>Pas du tout !</div>
		<div class="flex flex-col items-center pr-6" data-value="2" data-kid-story-id="${kidStoryId}" data-action="click->notification#setRating"><button class="swal2-logo swal2-button-2"></button>Pas trop !</div>
		<div class="flex flex-col items-center pr-6" data-value="3" data-kid-story-id="${kidStoryId}" data-action="click->notification#setRating"><button class="swal2-logo swal2-button-3"></button>Pas mal !</div>
		<div class="flex flex-col items-center pr-6" data-value="4" data-kid-story-id="${kidStoryId}" data-action="click->notification#setRating"><button class="swal2-logo swal2-button-4"></button>J'ai aimé !</div>
		<div class="flex flex-col items-center pr-6" data-value="5" data-kid-story-id="${kidStoryId}" data-action="click->notification#setRating"><button class="swal2-logo swal2-button-5"></button>J'ai adoré !</div>
	</div>
	`

export const wordsCountCampaignDone = (message, media_link) => `
	<div>
		<div>${message}</div>
		<br>
		<div>
			<img src="${media_link}" width="100%"/>
		</div>
	</div>
	`
