import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']
  static values = { id: Number }

  show(event) {
    event.preventDefault()
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove('hidden')
    }
  }

  hide(event) {
    event.preventDefault()
    if (this.hasCardTarget) {
      this.cardTarget.classList.add('hidden')
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove()
    }
  }
}
