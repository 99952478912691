import Controller from '../application_controller'
import confetti from 'canvas-confetti'
import { getMetaValue } from '../../utils'

import {
  swalNewBadge,
  swalNewReview,
  swalReviewConfirm,
  swalCorrectionRequestConfirm,
  swalNewCorrectionRequest,
  swalInformation,
  swalWordsCountCampaignDone
} from '../../templates/sweet_alerts'
import {
  notificationUnread,
  notificationMarkAsRead,
  createReview,
  createCorrectionRequest,
  notificationMarkAsReadWithId
} from '../../api'

export default class extends Controller {
  static targets = ['unreadMark', 'counter', 'dropdown']

  connect() {
    if (getMetaValue('enable_fetch_notification')) {
      this.fetchNotification()
    }
  }

  disconnect() {
    try {
      confetti.reset()
    } catch (error) {}
    this.element.remove()
  }

  async fetchNotification() {
    return setTimeout(() => {
      notificationUnread(this.kidId)
        .then(response => {
          const notification = response.data
          if (notification) this.renderNotification(notification)
        })
        .catch(this.handleError)
    }, 1000)
  }

  renderNotification({ alert, category, ...notification }) {
    if (!alert) return

    Sentry.captureMessage(`Front end notification category: ${category}`)

    switch (category) {
      case 'new_badge':
        this.renderBadge(notification)
        break
      case 'review':
        this.renderReview(notification)
        break
      case 'correction_request':
        this.renderCorrectionRequest(notification)
        break
      case 'information':
        this.renderInformation(notification)
        break
      case 'marathon':
        this.renderWordCountCampaign(notification)
        break
      case 'level_up':
        this.renderBadge(notification)
        break
      default:
    }
  }

  async setRating(event) {
    const rating = event.currentTarget.dataset.value
    const kidStoryId = event.currentTarget.dataset.kidStoryId

    createReview({ rating, kid_id: this.kidId, kid_story_id: kidStoryId })
  }

  markAsRead(event) {
    if (event.currentTarget.dataset.read == 'true') return
    event.stopPropagation()
    const notificationId = event.currentTarget.dataset.id
    const notifiedId = event.currentTarget.dataset.notifiedId
    const notifiedType = event.currentTarget.dataset.notifiedType
    notificationMarkAsReadWithId(notificationId, notifiedId, notifiedType)
    if (this.hasUnreadMarkTarget) {
      this.unreadMarkTargets.find(element => element.dataset.id == notificationId).remove()
    }
    if (this.hasCounterTarget) {
      let count = this.counterTarget.innerText
      if (count > 1) {
        this.counterTarget.innerText = count - 1
      } else if (count == 1) {
        this.counterTarget.remove()
      }
    }
  }

  async renderBadge({ title, message, image }) {
    return swalNewBadge(title, message, image)
      .then(() => this.fetchNotification())
      .catch(this.handleError)
  }

  async renderWordCountCampaign({ title, message, image }) {
    return swalWordsCountCampaignDone(title, message, image)
      .then(() => this.fetchNotification())
      .catch(this.handleError)
  }

  async renderInformation(notification) {
    return swalInformation(notification)
      .then(({ value }) => {
        if (value && notification && notification.swal && notification.swal.confirmRedirect) {
          window.location.href = notification.swal.confirmRedirect
        }
      })
      .catch(this.handleError)
  }

  async renderReview(notification) {
    const kidStoryId = notification.kid_story_id
    if (kidStoryId) {
      return swalNewReview(kidStoryId)
        .then(({ value }) => {
          if (value) {
            this.submitReview(value, kidStoryId)
              .then(() => swalReviewConfirm())
              .then(() => this.fetchNotification())
          }
        })
        .catch(this.handleError)
    }
  }

  async renderCorrectionRequest(notification) {
    const kidStoryId = notification.kid_story_id

    return swalNewCorrectionRequest(notification)
      .then(({ value }) => {
        if (value) {
          return this._submitCorrectionRequest(
            kidStoryId,
            "Bravo ! Ton histoire a bien été envoyée en relecture. Tu recevras une notification lorsqu'elle sera prête à être imprimée !"
          )
        } else {
          return this.fetchNotification()
        }
      })
      .catch(this.handleError)
  }

  async submitReview(event) {
    const kidStoryId = event.currentTarget.dataset.kidStoryId
    const comment = document.getElementById(`feedback_kid_story_${kidStoryId}`).value
    const data = {
      comment: comment,
      kid_id: this.kidId,
      kid_story_id: kidStoryId
    }
    return createReview(data).catch(this.handleError)
  }

  submitCorrectionRequest({ params }) {
    event.preventDefault()
    this._submitCorrectionRequest(params.kidStoryId, params.message, params.buttonText)
  }

  async _submitCorrectionRequest(kidStoryId, message, buttonText) {
    const data = {
      kid_id: this.kidId,
      kid_story_id: kidStoryId || (this.data.has('kidStoryId') && this.data.get('kidStoryId'))
    }
    this.fireWorks()
    return createCorrectionRequest(data)
      .then(({ data }) => {
        if (data.kind === 'story') {
          swalCorrectionRequestConfirm(message, buttonText)
            .then(() => location.reload())
            .catch(this.handleError)
        } else {
          location.reload()
        }
      })
      .catch(this.handleError)
  }

  fireWorks() {
    var end = Date.now() + 15 * 1000

    var interval = setInterval(function () {
      if (Date.now() > end) {
        return clearInterval(interval)
      }

      confetti({
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        shapes: ['square'],
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      })
    }, 200)
  }

  getRating(kidStoryId) {
    try {
      return localStorage.getItem(`rating_kid_story_id_${kidStoryId}`)
    } catch (error) {
      return ''
    }
  }
}
