import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    if (this.isContentEmpty) {
      this.element.remove()
    }
  }

  // private

  get isContentEmpty() {
    return (this.hasContentTarget ? this.contentTarget : this.element).textContent.trim() === ''
  }
}
