import Controller from '../application_controller'
import { Swiper, Navigation } from 'swiper'
import { useIntersection } from 'stimulus-use'

Swiper.use([Navigation])

export default class extends Controller {
  static targets = ['nextButton', 'prevButton', 'scrollBar', 'pagination']
  static values = { slidesPerView: String }
  intiialized = false

  connect() {
    useIntersection(this)
  }

  disconnect() {
    // Remove the element from the DOM to flicker effect with Turbo cache
    this.swiper?.destroy()
    this.element.classList.add('invisible')
  }

  appear() {
    if (!this.initialized) {
      this.initializeSwiper()
      this.initialized = true
    }
  }

  // private

  initializeSwiper() {
    this.swiper = new Swiper(this.element, {
      direction: 'horizontal',
      // simulateTouch: true,
      // centeredSlides: true,
      // loopedSlides: 7,
      // loop: true,
      // Navigation arrows
      slidesOffsetBefore: 10,
      slidesOffsetAfter: 10,
      watchOverflow: true,
      updateOnImagesReady: true,
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
        disabledClass: 'hidden'
      },
      breakpoints: {
        0: {
          slidesPerView: 2.2,
          spaceBetween: 10
          // slidesPerGroup: 2
        },
        320: {
          slidesPerView: 2.4,
          spaceBetween: 10
          // slidesPerGroup: 2
        },
        640: {
          slidesPerView: 3.2,
          spaceBetween: 10
          // slidesPerGroup: 3
        },
        768: {
          slidesPerView: 3.4,
          spaceBetween: 10
          // slidesPerGroup: 4
        },
        1024: {
          slidesPerView: 4.4,
          spaceBetween: 10
          // slidesPerGroup: slidesPerView
        },
        1440: {
          slidesPerView: 5.4,
          spaceBetween: 10
          // slidesPerGroup: slidesPerView
        }
      },
      on: {
        init: () => {
          this.element.classList.remove('opacity-0')
          this.element.classList.add('opacity-100')
        }
      }

      // breakpoints: {
      //   320: {
      //     slidesPerView: 1
      //   },
      //   640: {
      //     slidesPerView: 2
      //   },
      //   768: {
      //     slidesPerView: 4
      //   },
      //   1024: {
      //     slidesPerView: 5
      //   }
      // }
    })
  }
}
