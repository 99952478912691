import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { timingByEnv } from '../../../frontend/utils'

export default class extends Controller {
  static values = { delay: Number, event: { default: 'change', type: String } }
  static debounces = ['handler']

  initialize() {
    useDebounce(this, { wait: timingByEnv(300) })
  }

  connect() {
    this.elements.forEach(el => el.addEventListener(this.eventValue, this.handler))
  }

  disconnect() {
    this.elements.forEach(el => el.removeEventListener(this.eventValue, this.handler))
  }

  handler = e => {
    e.preventDefault()
    e.stopPropagation()

    this.element.requestSubmit()
  }

  get elements() {
    return this.element.querySelectorAll('input, select, textarea')
  }
}
