import Controller from '../application_controller'

export default class extends Controller {
  static targets = []
  static values = { delay: Number, onConnect: String, onDisconnect: String }

  connect() {
    if (this.isPreview) return

    switch (this.onConnectValue) {
      case 'fadeOut':
        requestAnimationFrame(() => {
          this.fadeOut()
        })
        break
      case 'fadeIn':
        requestAnimationFrame(() => {
          this.fadeIn()
        })
        break
      default:
        break
    }
  }

  disconnect() {
    switch (this.onDisconnectValue) {
      case 'fadeOut':
        this.fadeOut()
        break
      case 'fadeIn':
        this.fadeIn()
        break
      default:
        break
    }
  }

  fadeIn() {
    this.element.classList.add('opacity-100')
    this.element.classList.remove('opacity-0')
    this.element.classList.remove('hidden')
    if (this.delayValue !== 0) {
      setTimeout(() => {
        this.element.classList.remove('opacity-100')
        this.element.classList.add('hidden')
      }, this.delayValue)
    }
  }

  fadeOut() {
    this.element.classList.add('opacity-0')
    this.element.classList.remove('opacity-100')
    this.element.classList.add('hidden')
  }
}
