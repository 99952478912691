import Controller from '../application_controller'
import { useMemo } from 'stimulus-use'

// we had a strange bug where url params would cause the bootstrap tabs to be irresponsive
export default class extends Controller {
  static memos = ['url']

  initialize() {
    useMemo(this)
  }

  connect() {
    try {
      if (this.currentUrl !== this.currentUrlWithoutParams) {
        Turbo.visit(this.currentUrlWithoutParams, { action: 'replace' })
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  get url() {
    return new URL(window.location.href)
  }

  get currentUrl() {
    return this.url.href
  }

  get currentUrlWithoutParams() {
    return this.url.origin + this.url.pathname + this.url.hash
  }
}
