export function timingByEnv(value) {
  return isTestEnvironement ? value / 100 : value
}

export function getMetaValue(name, defaultValue = '') {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return (element && autoCasting(element.getAttribute('content'))) || defaultValue
}

export const isMobile = getMetaValue('mobile')

export const isTestEnvironement = getMetaValue('environment') === 'test'

export const isBetaTester = getMetaValue('tester')

function autoCasting(value) {
  try {
    return JSON.parse(value)
  } catch (_) {
    return value
  }
}
