import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import utilScript from 'intl-tel-input/build/js/utils.js'
import { Current } from 'current.js'

export default class extends Controller {
  static targets = ['input']

  // lifecycle
  connect() {
    this.#initializeIntlTelInput()
    this.#disableFormSubmit()
  }

  disconnect() {
    this.inputTarget.removeEventListener('keypressup', this.#setFullNumber)
    this.inputTarget.removeEventListener('countrychange', this.#setFullNumber)
    this.inputTarget.removeEventListener('blur', this.#validate)
    this.intlInput.destroy()
  }

  // private

  #disableFormSubmit() {
    this.submitButton.addEventListener('click', this.#validate.bind(this))
  }

  #enableFormSubmit() {
    this.submitButton.removeEventListener('click', this.#validate.bind(this))
  }

  #initializeIntlTelInput() {
    this.intlInput = intlTelInput(this.inputTarget, {
      utilsScript: utilScript,
      autoInsertDialCode: true,
      hiddenInput: 'full',
      autoPlaceholder: 'aggressive',
      initialCountry: 'auto',
      preferredCountries: this.preferedCountries,
      separateDialCode: true,
      geoIpLookup: function (callback) {
        fetch('https://ipapi.co/json')
          .then(function (res) {
            return res.json()
          })
          .then(function (data) {
            callback(data.country_code)
          })
          .catch(function () {
            callback(Current.locale)
          })
      }
    })

    this.inputTarget.addEventListener('keyup', this.#setFullNumber.bind(this))
    this.inputTarget.addEventListener('blur', this.#validate.bind(this))
    this.inputTarget.addEventListener('countrychange', this.#setFullNumber.bind(this))
  }

  #setFullNumber() {
    if (this.intlInput.isValidNumber()) {
      this.fullTarget.value = this.intlInput.getNumber()
      this.#enableFormSubmit()
      this.inputTarget.classList.remove('is-invalid')
      this.inputTarget.classList.add('is-valid')
    } else {
      this.fullTarget.value = ''
      this.#disableFormSubmit()
    }
  }

  #validate(event) {
    if (this.intlInput.isValidNumber()) {
      return true
    }
    event.preventDefault()

    this.inputTarget.classList.add('is-invalid')
    this.inputTarget.classList.remove('is-valid')
  }

  // getters
  get preferedCountries() {
    return Current.locale === 'fr' ? ['fr', 'lu', 'be', 'ch'] : ['us', 'ca', 'gb', 'ie', 'au']
  }

  get fullTarget() {
    return this.element.querySelector('input[type=hidden]')
  }

  get submitButton() {
    return this.element.closest('form').querySelector('button[type=submit]')
  }
}
